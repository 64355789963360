import { useSearchParams } from '@solidjs/router';
import dayjs from 'dayjs';
import { createContext, createSignal, onMount, useContext } from 'solid-js';
import { isServer } from 'solid-js/web';
import { withScope } from '@sentry/solidstart';
import type { Accessor, ParentProps } from 'solid-js';

const keys = ['campaign', 'source', 'medium', 'term', 'content'] as const;

type UTMKey = (typeof keys)[number];

type Params = Record<UTMKey, string | undefined>;

const storageKey = 'utm';

const Ctx = createContext<{ utm: Accessor<Params> }>({ utm: () => ({}) as Params });

/**
 * Non-reactive source-param tracker with saving to localstorage
 */
export function UtmProvider(props: ParentProps) {
	const [search] = useSearchParams();
	const { params, hasUTM } = getUTM(search);
	const [utm, setUtm] = createSignal(params);

	onMount(() => {
		if (!isServer) {
			try {
				if (hasUTM) {
					localStorage.setItem(storageKey, JSON.stringify({ ...params, created: Date.now() }));
					setUtm(params);
				}
			} catch (e) {
				withScope((scope) => {
					scope.setLevel('warning');
					scope.captureException(e);
				});
			}

			// localstorage is known to be finicky. guard and don't worry about fallback for now

			try {
				const storage = localStorage.getItem(storageKey);
				if (storage) {
					const { created, ...stored } = JSON.parse(storage) as Params & { created: number };
					const ttlMet = dayjs().isAfter(dayjs(created).add(1, 'hour'));
					if (stored && !ttlMet) {
						setUtm(stored);
					} else if (ttlMet) {
						// Delete the stored source after 1 hour
						localStorage.removeItem('utm');
					}
				}
			} catch (e) {
				withScope((scope) => {
					scope.setLevel('warning');
					scope.captureException(e);
				});
			}
		}
	});

	return <Ctx.Provider value={{ utm }}>{props.children}</Ctx.Provider>;
}

export function useUtmParams() {
	const ctx = useContext(Ctx);
	return ctx.utm;
}

function getUTM(input: Partial<Params>) {
	const params: Params = {
		campaign: undefined,
		source: undefined,
		medium: undefined,
		term: undefined,
		content: undefined,
	};
	let hasUTM = false;
	for (const [key, val] of Object.entries(input)) {
		if (keys.findIndex((utmKey) => utmKey === key || utmKey === `utm_${key}`)) {
			hasUTM = true;
			params[key.replace(/^utm_/, '') as UTMKey] = val;
		}
	}
	return { params, hasUTM };
}
